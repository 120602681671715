import React, { Component } from 'react'
import BackgroundVideo from './backgroundVideo';
import '../resources/css/kate.css';
import '../resources/css/main-unbounce.css';

// https://use.evabot.ai/top-agent-use-eva-fsb/
export default class Kate001 extends Component {
    state = {
        registerUrl: "https://dashboard.evabot.ai/eva-demo?p=fsb&amp;mode=ad",
        calendlyUrl: "",
        isBoxVisible: 0
    };
    componentDidMount() {
        let { registerUrl, calendlyUrl, isBoxVisible } = [...this.state.registerUrl, ...this.state.calendlyUrl];
        //let calendlyUrl = [...this.state.calendlyUrl];
        if (this.props.location.pathname.indexOf("hooquest") !== -1) {
            registerUrl = "https://dashboard.evabot.ai/signup?ic=Hooquest7234&redirect=LimitedTimeOffer/hooquestOffers?isd=1";
            calendlyUrl = "https://calendly.com/rabi/learn-more-about-eva-gifting-automation-via-hooquest"
            isBoxVisible = 1
            this.setState({ registerUrl, calendlyUrl, isBoxVisible });
        } else if (this.props.location.pathname.indexOf("perkplans") !== -1) {
            registerUrl = "https://dashboard.evabot.ai/signup?ic=Nemeroff0513&redirect=LimitedTimeOffer/perkplansOffers?isd=1";
            calendlyUrl = "https://calendly.com/rabi/learn-more-about-eva-gifting-automation-via-perkplans"
            isBoxVisible = 1
            this.setState({ registerUrl, calendlyUrl, isBoxVisible });
        }
    }



    render() {
        return (
            <div>
                <div className="lp-element lp-pom-root" id="lp-pom-root">
                    <div id="lp-pom-root-color-overlay"></div>
                    <div className="lp-positioned-content">
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-137">
                            <p className="lplh-19" style={{ textAlign: 'center' }}><span style={{ color: '#696969' }}><span
                                style={{ fontSize: '12px' }}>Copyright © 2020&nbsp; EvaBot Inc.</span></span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-253">
                            <p style={{ lineHeight: '58px', textAlign: 'center' }}><span style={{ fontSize: '50px' }}><span
                                style={{ color: 'rgb(255, 255, 255)' }}><span style={{ fontStyle: 'normal' }}><span
                                    style={{ fontWeight: 300 }}><span
                                        style={{ fontFamily: 'Open Sans' }}><strong>Send great gifts, get 2X the
                                        testimonials and drive 4X the
                                            referrals.</strong></span></span></span></span></span></p>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-269">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}><a
                                href="https://www.evabot.ai/?p=facebook-home" target="_self">
                                <img src="//evabot.ai/img/kate-001/logo2.png" alt="" /></a>
                            </div>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-537">
                            <p style={{ lineHeight: '20px' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}>Gifts
                        that perfectly match</span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-538">
                            <h2 style={{ lineHeight: '45px', textAlign: 'center' }}><span style={{ color: '#585858' }}><span
                                style={{ fontSize: '33px', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }}>Why
                            Thousands of Realtors Have Chosen Eva?</span></span></h2>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-559">
                            <p style={{ lineHeight: '32px', textAlign: 'center' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: '#585858' }}>Drive
                        ROI Through Gifitng</span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-560">
                            <p style={{ lineHeight: '20px', textAlign: 'center' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(248, 248, 248)' }}>*No
                        Credit Card Required</span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-583">
                            <h2 style={{ lineHeight: '38px', textAlign: 'center' }}><span style={{ color: '#585858' }}><span
                                style={{ fontSize: '33px', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }}>What
                            Realtors Are Saying About Eva</span></span></h2>
                        </div>
                        <div className="lp-element lp-pom-box" id="lp-pom-box-584">
                            <div id="lp-pom-box-584-color-overlay"></div>
                            <div className="lp-element lp-pom-text nlh" id="lp-pom-text-585">
                                <h2 style={{ lineHeight: '38px', textAlign: 'center' }}><span
                                    style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '20px', color: 'rgb(58, 54, 54)' }}><strong><span
                                        style={{ fontStyle: 'normal' }}>"It's Social Media Gold"</span></strong></span></h2>
                            </div>
                            <div className="lp-element lp-pom-text nlh" id="lp-pom-text-586">
                                <h2 style={{ lineHeight: '22px', textAlign: 'center' }}><span
                                    style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '18px', color: 'rgb(58, 54, 54)' }}><span
                                        style={{ fontStyle: 'normal' }}>Handcrafted gifts with handwritten notes and it collects
                                testimonials for you. Its social media gold!</span></span></h2>
                            </div>
                            <div className="lp-element lp-pom-text nlh" id="lp-pom-text-587">
                                <h2 style={{ lineHeight: '38px', textAlign: 'center' }}><span
                                    style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '20px', color: 'rgb(239, 71, 72)' }}><strong><span
                                        style={{ fontStyle: 'normal' }}>Peter Lorimer</span></strong></span></h2>
                            </div>
                            <div className="lp-element lp-pom-image" id="lp-pom-image-588">
                                <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                    <img src="//evabot.ai/img/kate-001/peter-lorimer.png" alt="" />
                                </div>
                            </div>
                            <div className="lp-element lp-pom-text nlh" id="lp-pom-text-589">
                                <h2 style={{ lineHeight: '22px', textAlign: 'center' }}><span
                                    style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '14px', color: 'rgb(58, 54, 54)' }}><span
                                        style={{ fontStyle: 'normal' }}>Founder PLG Real Estate &amp; Co-host ‘Stay Here’ on
                                Netflix</span></span></h2>
                            </div>
                        </div>
                        <div className="lp-element lp-pom-box" id="lp-pom-box-596">
                            <div id="lp-pom-box-596-color-overlay"></div>
                            <div className="lp-element lp-pom-text nlh" id="lp-pom-text-597">
                                <h2 style={{ lineHeight: '38px', textAlign: 'center' }}><span
                                    style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '20px', color: 'rgb(58, 54, 54)' }}><strong><span
                                        style={{ fontStyle: 'normal' }}>"Referral Machine"</span></strong></span></h2>
                            </div>
                            <div className="lp-element lp-pom-text nlh" id="lp-pom-text-598">
                                <h2 style={{ lineHeight: '22px', textAlign: 'center' }}><span
                                    style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '18px', color: 'rgb(58, 54, 54)' }}><span
                                        style={{ fontStyle: 'normal' }}>Eva has supercharged my referral game. And it saves me so
                                much time!</span></span></h2>
                            </div>
                            <div className="lp-element lp-pom-text nlh" id="lp-pom-text-599">
                                <h2 style={{ lineHeight: '38px', textAlign: 'center' }}><span
                                    style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '20px', color: 'rgb(239, 71, 72)' }}><strong><span
                                        style={{ fontStyle: 'normal' }}>David Poulsen</span></strong></span></h2>
                            </div>
                            <div className="lp-element lp-pom-image" id="lp-pom-image-600">
                                <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                    <img src="//evabot.ai/img/kate-001/david.png" alt="" />
                                </div>
                            </div>
                            <div className="lp-element lp-pom-text nlh" id="lp-pom-text-601">
                                <h2 style={{ lineHeight: '22px', textAlign: 'center' }}><span
                                    style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '14px', color: 'rgb(58, 54, 54)' }}><span
                                        style={{ fontStyle: 'normal' }}>Top SF Bay Area Agent at KWSF</span></span></h2>
                            </div>
                        </div><a className="lp-element lp-pom-button" id="lp-pom-button-604"
                            href={this.state.registerUrl} target="_self"><span
                                className="label">Try Eva Free</span></a>
                        {this.state.isBoxVisible === 1 && (<a className="lp-element lp-pom-button" id="lp-pom-button-6040" onClick={() => { window.Calendly.initPopupWidget({ url: this.state.calendlyUrl }) }} style={{
                            position: 'absolute', fontFamily: 'Lato', fontWeight: 400, fontSize: '14px'
                        }}>Got questions? Schedule a call with us.</a>)} isBoxVisible:{this.state.isBoxVisible}
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-605">
                            <p style={{ lineHeight: '32px', textAlign: 'center' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '28px', color: 'rgb(239, 71, 72)' }}>Sign
                        up now and get $25</span></p>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-609">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/positive-vote.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-610">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/giftbox.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-611">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/like.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-613">
                            <p style={{ lineHeight: '20px' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}>Collect
                        reviews on Google, Yelp, TrustPilot, Zillow</span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-614">
                            <p style={{ lineHeight: '20px' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}>Custom
                        printed branded gift boxes and Handwritten notes</span></p>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-643">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}><img src="//evabot.ai/img/kate-001/compass-logo.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-648">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}><img src="//evabot.ai/img/kate-001/zephyr.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-653">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/interro-logo.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-654">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/barb.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-655">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/legacy-logo-horizontal.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-657">
                            <h2 style={{ lineHeight: '45px', textAlign: 'center' }}><span style={{ color: '#585858' }}><span
                                style={{ fontSize: '33px', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }}>20,000+
                            Personalized Gifts Shipped</span></span></h2>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-658">
                            <p style={{ lineHeight: '32px', textAlign: 'center' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '25px', color: '#585858' }}>Drive
                        ROI Through Gifting</span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-660">
                            <h2 style={{ lineHeight: '45px', textAlign: 'center' }}><span style={{ color: '#585858' }}><span
                                style={{ fontSize: '28px', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }}>Why
                            Thousands of Realtors Have Chosen Eva?</span></span></h2>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-665">
                            <p style={{ lineHeight: '26px' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '20px', color: 'rgb(58, 54, 54)' }}>Gifts
                        that perfectly match</span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-666">
                            <p style={{ lineHeight: '26px' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '20px', color: 'rgb(58, 54, 54)' }}>Collect
                        reviews on Google, Yelp, TrustPilot, Zillow</span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-667">
                            <p style={{ lineHeight: '26px' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '20px', color: 'rgb(58, 54, 54)' }}>Custom
                        printed branded gift boxes and handwritten notes</span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-669">
                            <h2 style={{ lineHeight: '38px', textAlign: 'center' }}><span style={{ color: '#585858' }}><span
                                style={{ fontSize: '33px', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }}>20,000+
                            Personalized Gifts Shipped</span></span></h2>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-671">
                            <h2 style={{ lineHeight: '38px', textAlign: 'center' }}><span style={{ color: '#585858' }}><span
                                style={{ fontSize: '33px', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }}>14,000+
                            Customer Reviews Collected</span></span></h2>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-672">
                            <h2 style={{ lineHeight: '45px', textAlign: 'center' }}><span style={{ color: '#585858' }}><span
                                style={{ fontSize: '33px', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }}>14,000+
                            Customer Reviews Collected</span></span></h2>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-673">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/screen-shot-2019-05-22-at-2-39-34-pm.jpg" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-682">
                            <h2 style={{ lineHeight: '38px', textAlign: 'center' }}><span style={{ color: 'rgb(239, 71, 72)' }}><span
                                style={{ fontSize: '33px', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }}>See
                            How Eva Can Help You</span></span></h2>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-688">
                            <p style={{ lineHeight: '26px' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}>Add
                        Custom Branded gift Boxes &amp; Handwritten Notes</span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-689">
                            <p style={{ lineHeight: '26px' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}>Collect
                            Customer Reviews on Public Pages&nbsp;</span></span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-690">
                            <p style={{ lineHeight: '20px' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><span
                                        style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}>Gifts
                                Starting From $30</span></span></span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-691">
                            <p style={{ lineHeight: '20px' }}><span
                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><span
                                        style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><span
                                            style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><span
                                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><span
                                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}>Flat
                                            Shipping&nbsp;<span
                                                        style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><span
                                                            style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><span
                                                                style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '24px', color: 'rgb(58, 54, 54)' }}>Packaging&nbsp;</span></span></span></span></span></span></span></span></span>
                            </p>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-692">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/checked.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-693">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/checked.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-694">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/checked.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-695">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/checked.png" alt="" />
                            </div>
                        </div><a className="lp-element lp-pom-button" id="lp-pom-button-723"
                            href={this.state.registerUrl} target="_self"><span
                                className="label">Try Eva Free</span></a>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-762">
                            <h2 style={{ lineHeight: '35px', textAlign: 'center' }}><span
                                style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '24px', color: 'rgb(58, 54, 54)' }}><strong><span
                                    style={{ fontStyle: 'normal' }}>"5 Stars to Leslie"</span></strong></span></h2>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-763">
                            <h2 style={{ lineHeight: '26px', textAlign: 'center' }}><span
                                style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '22px', color: 'rgb(58, 54, 54)' }}><span
                                    style={{ fontStyle: 'normal' }}>5 stars for the creativeness of the present. 5 stars to Leslie
                            for the super thoughtful gift</span></span><span
                                    style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '18px', color: 'rgb(58, 54, 54)' }}><span
                                        style={{ fontStyle: 'normal' }}><br /></span></span></h2>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-764">
                            <h2 style={{ lineHeight: '38px', textAlign: 'center' }}><span
                                style={{ fontWeight: 300, fontFamily: 'Lato', fontSize: '24px', color: 'rgb(239, 71, 72)' }}><strong><span
                                    style={{ fontStyle: 'normal' }}>- Eva Gift Recipient </span></strong></span></h2>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-769">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/image-2-2x.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-787">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/screen-shot-2019-03-15-at-12-00-12-pm-1.jpg" alt="" />
                            </div>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-788">
                            <p style={{ lineHeight: '42px', textAlign: 'center' }}><span style={{ fontSize: '36px' }}><span
                                style={{ color: 'rgb(255, 255, 255)' }}><span style={{ fontStyle: 'normal' }}><span
                                    style={{ fontWeight: 300 }}><span
                                        style={{ fontFamily: 'Open Sans' }}><strong>Send great gifts, get 2X the
                                        testimonials and drive 4X the
                                            referrals.&nbsp;</strong></span></span></span></span></span></p>
                            <p style={{ lineHeight: '42px', textAlign: 'center' }}><span style={{ fontSize: '36px' }}><span
                                style={{ color: 'rgb(255, 255, 255)' }}><span style={{ fontStyle: 'normal' }}><span
                                    style={{ fontWeight: 300 }}><span
                                        style={{ fontFamily: 'Open Sans' }}><strong>All with one simple
                                            tool.</strong></span></span></span></span></span></p>
                        </div>
                        <div className="lp-element lp-pom-text nlh" id="lp-pom-text-790">
                            <p style={{ lineHeight: '35px', textAlign: 'center' }}><span style={{ fontSize: '50px' }}><span
                                style={{ color: 'rgb(255, 255, 255)' }}><span style={{ fontStyle: 'normal' }}><span
                                    style={{ fontWeight: 300 }}><span
                                        style={{ fontFamily: 'Open Sans' }}><strong>All with one simple
                                            tool.</strong></span></span></span></span></span></p>
                        </div>
                        <div className="lp-element lp-pom-image" id="lp-pom-image-796">
                            <div className="lp-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/kate-001/civic-secondary-logo-rgb.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="lp-element lp-pom-block" id="lp-pom-block-261">
                        <BackgroundVideo />
                    </div>
                    <div >

                    </div>
                    <div className="lp-element lp-pom-block" id="lp-pom-block-558">
                        <div id="lp-pom-block-558-color-overlay"></div>
                        <div className="lp-pom-block-content"></div>
                    </div>
                    <div className="lp-element lp-pom-block" id="lp-pom-block-533">
                        <div id="lp-pom-block-533-color-overlay"></div>
                        <div className="lp-pom-block-content"></div>
                    </div>
                    <div className="lp-element lp-pom-block" id="lp-pom-block-642">
                        <div id="lp-pom-block-642-color-overlay"></div>
                        <div className="lp-pom-block-content"></div>
                    </div>
                    <div className="lp-element lp-pom-block" id="lp-pom-block-656">
                        <div id="lp-pom-block-656-color-overlay"></div>
                        <div className="lp-pom-block-content"></div>
                    </div>
                    <div className="lp-element lp-pom-block" id="lp-pom-block-582">
                        <div id="lp-pom-block-582-color-overlay"></div>
                        <div className="lp-pom-block-content"></div>
                    </div>
                    <div className="lp-element lp-pom-block" id="lp-pom-block-622">
                        <div id="lp-pom-block-622-color-overlay"></div>
                        <div className="lp-pom-block-content"></div>
                    </div>
                    <div className="lp-element lp-pom-block" id="lp-pom-block-430">
                        <div id="lp-pom-block-430-color-overlay"></div>
                        <div className="lp-pom-block-content"></div>
                    </div>
                    <div className="lp-element lp-pom-block" id="lp-pom-block-21">
                        <div id="lp-pom-block-21-color-overlay"></div>
                        <div className="lp-pom-block-content"></div>
                    </div>
                </div>
            </div >
        )
    }
}
