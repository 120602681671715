import React, { Component } from 'react'
import BackgroundVideo from './backgroundVideo';
import '../resources/css/top-agent.css';
import '../resources/css/main-leaf.css';


//https://use.evabot.ai/top-agent-use-eva-fsb/
function validate(email) {
    // true means invalid, so our conditions got reversed
    if (email.length === 0)
        return true;
    else
        return {
            email: (!/^.+@.+\..+$/.test(email))
        };
}

export default class TopAgent extends Component {
    state = {
        email: ""
    };

    handleEmailChange = (evt) => {
        this.setState({ email: evt.target.value });
    };

    handlePasswordChange = (evt) => {
        this.setState({ password: evt.target.value });
    };

    handleSubmit = (evt) => {
        if (!this.canBeSubmitted()) {
            evt.preventDefault();
            return;
        }
        const { email } = this.state;
        fetch(
            "https://api.evabot.ai/unbounce/newLead",
            //"http://localhost:8306/unbounce/newLead",
            { method: "post", body: JSON.stringify({ Email: email, Page: 'Top-Agent', Url: 'top-agent-use-eva-fsb' }) }
        )
            .then(
                setTimeout(() => { window.location = `https://dashboard.evabot.ai/eva-demo?p=fsb&mode=ad&email=${email}` })
            )
            .catch(console.log);

    };

    canBeSubmitted() {
        const errors = validate(this.state.email);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        return !isDisabled;
    }

    render() {
        const errors = validate(this.state.email);
        const isDisabled = Object.keys(errors).some(x => errors[x]);

        return (
            <div>
                <div className="lp-element ip-pom-root" id="ip-pom-root">
                    <div id="ip-pom-root-color-overlay"></div>
                    <div className="lp-positioned-content">
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-137">
                            <p className="lplh-19" style={{ textAlign: 'center' }}>
                                <span style={{ color: '#696969' }}><span style={{ fontSize: '12px' }}>Copyright &copy; 2020 EvaBot
                            Inc.</span></span>
                            </p>
                        </div>
                        <div className="lp-element ip-pom-box" id="ip-pom-box-251">
                            <div id="ip-pom-box-251-color-overlay"></div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-252">
                                <p className="lplh-58" style={{ lineHeight: '42px' }}>
                                    <span style={{ fontFamily: 'montserrat' }}><span style={{ fontSize: '32px' }}><span
                                        style={{ fontFamily: 'Lato', fontStyle: 'normal', fontSize: '40px', color: 'rgb(62, 62, 62)', backgroundColor: 'rgba(9, 9, 9, 0)' }}>Meet
                                        Eva, your personal gifting assistant:</span></span></span>
                                </p>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-253">
                                <p className="lplh-26">
                                    <span style={{ color: 'rgb(68, 68, 68)' }}><span style={{ fontSize: '24px' }}><span
                                        style={{ fontFamily: 'Open Sans', backgroundColor: 'rgba(0, 0, 0, 0)', fontWeight: 400, fontStyle: 'normal' }}>-
                                    Send closing gifts at the push of a button</span></span></span>
                                </p>
                                <p className="lplh-26">
                                    <span style={{ color: 'rgb(68, 68, 68)' }}><span style={{ fontStyle: 'normal' }}><span
                                        style={{ fontWeight: 400 }}><span style={{ fontFamily: 'Open Sans' }}><span
                                            style={{ fontSize: '24px' }}><span style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>-
                                                Leave lasting impressions</span></span></span></span></span></span>
                                </p>
                                <p className="lplh-26">
                                    <span style={{ color: 'rgb(68, 68, 68)' }}><span style={{ fontStyle: 'normal' }}><span
                                        style={{ fontWeight: 400 }}><span style={{ fontFamily: 'Open Sans' }}><span
                                            style={{ fontSize: '24px' }}><span style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>-
                                                Personalize every gift sent</span></span></span></span></span></span>
                                </p>
                                <p className="lplh-26">
                                    <span style={{ color: 'rgb(68, 68, 68)' }}><span style={{ fontStyle: 'normal' }}><span
                                        style={{ fontWeight: 400 }}><span style={{ fontFamily: 'Open Sans' }}><span
                                            style={{ fontSize: '24px' }}><span style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>-
                                            Free up time to grow your business
                                        </span></span></span></span></span></span>
                                </p>
                            </div>
                            <div className="lp-element ip-pom-form has-axis" id="ip-pom-form-449">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="fields">
                                        <div className="ip-pom-form-field email" id="container_email">
                                            <input style={{ fontFamily: 'Arial, sans-serif' }}
                                                className="ub-input-item single text form_elem_email"
                                                type="text"
                                                placeholder="Email"
                                                value={this.state.email}
                                                onChange={this.handleEmailChange}
                                            />
                                        </div>
                                    </div>
                                    <button disabled={isDisabled} className="lp-element ip-pom-button" id="ip-pom-button-450" type="submit"><span
                                        className="label"><strong><b>Claim Free Credit</b></strong></span></button>
                                </form>
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-269">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <a href="https://www.evabot.ai" target="_blank">
                                    <img src="//evabot.ai/img/top-agent/logo2.png"
                                        alt="" /></a>
                            </div>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-393">
                            <p style={{ lineHeight: '32px', textAlign: 'center' }}>
                                <span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '26px', color: 'rgb(248, 248, 248)' }}>Your
                        $25 dollar gift credit is waiting. Sign up to claim</span><span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '26px', color: 'rgb(255, 255, 255)' }}>.</span>
                            </p>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-477">
                            <h2 style={{ lineHeight: '38px' }}>
                                <span style={{ color: 'rgb(253, 75, 55)' }}><span
                                    style={{ fontSize: '24px', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }}>Our
                            Boxes, Your Brand</span></span>
                            </h2>
                        </div>
                        <div className="lp-element ip-pom-box" id="ip-pom-box-495">
                            <div id="ip-pom-box-495-color-overlay"></div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-404">
                                <h2 style={{ lineHeight: '38px', textAlign: 'center' }}>
                                    <span style={{ color: 'rgb(58, 54, 54)' }}><span
                                        style={{ fontSize: '36px', fontFamily: 'Open Sans', fontWeight: 400, fontStyle: 'normal' }}>Delight
                                Your Customers and Prospects </span></span>
                                </h2>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-499">
                                <h2 style={{ lineHeight: '38px' }}>
                                    <span style={{ color: 'rgb(253, 75, 55)', fontFamily: 'Open Sans' }}><span
                                        style={{ fontSize: '24px', fontWeight: 400 }}>Listing Meetings</span></span>
                                </h2>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-500">
                                <p style={{ lineHeight: '20px' }}>
                                    <span
                                        style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(58, 54, 54)' }}>Sending
                                        a gift at the end of a listing meeting leaves a great impression, while highlighting how
                            tech savvy you are as an agent.&nbsp;</span>
                                </p>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-516">
                                <h2 style={{ lineHeight: '38px' }}>
                                    <span style={{ color: 'rgb(253, 75, 55)', fontFamily: 'Open Sans' }}><span
                                        style={{ fontSize: '24px', fontWeight: 400 }}>Birthdays</span></span>
                                </h2>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-517">
                                <p style={{ lineHeight: '20px' }}>
                                    <span
                                        style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(58, 54, 54)' }}>The
                                        perfect time to remind a client that you care | Use our bulk feature to schedule gifts for
                            years to come.&nbsp;</span>
                                </p>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-520">
                                <h2 style={{ lineHeight: '38px' }}>
                                    <font color="#fd4b37" face="Open Sans"><span style={{ fontSize: '24px', fontWeight: 400 }}>House
                                Anniversaries &nbsp;</span></font>
                                </h2>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-521">
                                <p style={{ lineHeight: '20px' }}>
                                    <span
                                        style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(58, 54, 54)' }}>80%
                                        of Home Buyers say they would use their agent again, only 20% actually do. House
                                        anniversarries keep you and your practice top of mind for the next time your clients look to
                            buy a house.&nbsp;</span>
                                </p>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-598">
                                <p style={{ lineHeight: '32px', textAlign: 'center' }}>
                                    <span
                                        style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '26px', color: 'rgb(92, 92, 92)' }}>Show
                            your customers how important they are with personalized, memorable gifts.&nbsp;</span>
                                </p>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-604">
                                <h2 style={{ lineHeight: '38px' }}>
                                    <span style={{ color: 'rgb(253, 75, 55)', fontFamily: 'Open Sans' }}><span
                                        style={{ fontSize: '24px', fontWeight: 400 }}>Referrals</span></span>
                                </h2>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-605">
                                <p style={{ lineHeight: '20px' }}>
                                    <span
                                        style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(58, 54, 54)' }}>We
                                        all know how important Referrals are - let's make sure we thank those who send us new
                            business.&nbsp;</span>
                                </p>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-606">
                                <h2 style={{ lineHeight: '38px' }}>
                                    <span style={{ color: 'rgb(253, 75, 55)', fontFamily: 'Open Sans' }}><span
                                        style={{ fontSize: '24px', fontWeight: 400 }}>Closing Gifts</span></span>
                                </h2>
                            </div>
                            <div className="lp-element ip-pom-text nlh" id="ip-pom-text-607">
                                <p style={{ lineHeight: '20px' }}>
                                    <span
                                        style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(58, 54, 54)' }}>There's
                            no better time to send a thank you gift to a client. &nbsp;</span>
                                </p>
                            </div>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-543">
                            <p style={{ lineHeight: '20px' }}>
                                <span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(58, 54, 54)' }}>For
                                    a truly unique experience, add your brand to our boxes. Just send your logo to our team after
                        purchasing a plan, and our professional designers take it from there.&nbsp;</span>
                            </p>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-544">
                            <p style={{ lineHeight: '20px', textAlign: 'center' }}>
                                <span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(248, 248, 248)' }}>*No
                        Credit Card Required</span>
                            </p>
                        </div>
                        <div className="lp-element ip-pom-box" id="ip-pom-box-547">
                            <div id="ip-pom-box-547-color-overlay"></div>
                            <div className="lp-element ip-pom-image" id="ip-pom-image-548">
                                <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                    <img src="//evabot.ai/img/top-agent/b355f784-evabot-compass-testimonials-ken.jpg" alt="" />
                                </div>
                            </div>
                            <div className="lp-element ip-pom-image" id="ip-pom-image-555">
                                <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                    <img src="//evabot.ai/img/top-agent/b9ddf9a8-evabot-compass-testimonials-mobile-ken.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-552">
                            <p style={{ lineHeight: '20px' }}>
                                <span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(58, 54, 54)' }}><em>We
                                    absolutely love how easy Eva makes it to send out an awesome gift. And the way it collects
                            customer birthdays and sends a gift automatically is just magical! </em></span>
                            </p>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-553">
                            <p style={{ lineHeight: '20px' }}>
                                <span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(58, 54, 54)' }}><strong>Top
                            SF and East Bay Real Estate Team</strong></span>
                            </p>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-554">
                            <p style={{ lineHeight: '20px' }}>
                                <span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(58, 54, 54)' }}>The
                        Katy Team</span>
                            </p>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-559">
                            <p style={{ lineHeight: '32px', textAlign: 'center' }}>
                                <span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '26px', color: 'rgb(248, 248, 248)' }}>Your
                        $25 dollar gift credit is waiting. Sign up to claim</span><span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '26px', color: 'rgb(255, 255, 255)' }}>.</span>
                            </p>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-560">
                            <p style={{ lineHeight: '20px', textAlign: 'center' }}>
                                <span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(248, 248, 248)' }}>*No
                        Credit Card Required</span>
                            </p>
                        </div>
                        <a className="lp-element ip-pom-button" id="ip-pom-button-561" href="#ip-pom-block-261" target="_self"><span
                            className="label"><strong><b>Try Eva Free</b></strong></span></a>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-563">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/ae4c9b24-compass.jpg" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-564">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/2bbc29e7-climb-100-min.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-565">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/47c3acaa-golden-gate-sothebys-logo-min.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-566">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/8ee75cb8-kw-min.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-567">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/b2e8a750-century21-logo-min.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-568">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/a7438705-civic.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-569">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/6a697e3d-zp-min.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-571">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/33de82bf-remax-mastrlogotype-rgb-r-min.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-580">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/fceddc90-anthony-kellerwilliams-top.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-581">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/35c2078c-bh-top.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-582">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/9baf2773-karlton-uhm-top.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-583">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/60a27565-kimber-top.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-584">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/bca27546-saramoran-top.png" alt="" />
                            </div>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-631">
                            <p style={{ lineHeight: '32px', textAlign: 'center' }}>
                                <span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '26px', color: 'rgb(248, 248, 248)' }}>Your
                        $25 dollar gift credit is waiting. Sign up to claim</span><span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '26px', color: 'rgb(255, 255, 255)' }}>.</span>
                            </p>
                        </div>
                        <div className="lp-element ip-pom-text nlh" id="ip-pom-text-632">
                            <p style={{ lineHeight: '20px', textAlign: 'center' }}>
                                <span
                                    style={{ fontFamily: 'Lato', fontWeight: 300, fontStyle: 'normal', fontSize: '18px', color: 'rgb(248, 248, 248)' }}>*No
                        Credit Card Required</span>
                            </p>
                        </div>
                        <a className="lp-element ip-pom-button" id="ip-pom-button-633" href="#ip-pom-block-261" target="_self"><span
                            className="label"><strong><b>Try Eva Free</b></strong></span></a>
                        <div className="lp-element ip-pom-image" id="ip-pom-image-634">
                            <div className="ip-pom-image-container" style={{ overflow: 'hidden' }}>
                                <img src="//evabot.ai/img/top-agent/48ce33a0-tim-samuels-top.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="lp-element ip-pom-block" id="ip-pom-block-261">
                        <BackgroundVideo />
                    </div>
                    <div className="lp-element ip-pom-block" id="ip-pom-block-558">
                        <div id="ip-pom-block-558-color-overlay"></div>
                        <div className="ip-pom-block-content"></div>
                    </div>
                    <div className="lp-element ip-pom-block" id="ip-pom-block-476">
                        <div id="ip-pom-block-476-color-overlay"></div>
                        <div className="ip-pom-block-content"></div>
                    </div>
                    <div className="lp-element ip-pom-block" id="ip-pom-block-533">
                        <div id="ip-pom-block-533-color-overlay"></div>
                        <div className="ip-pom-block-content"></div>
                    </div>
                    <div className="lp-element ip-pom-block" id="ip-pom-block-630">
                        <div id="ip-pom-block-630-color-overlay"></div>
                        <div className="ip-pom-block-content"></div>
                    </div>
                    <div className="lp-element ip-pom-block" id="ip-pom-block-430">
                        <div id="ip-pom-block-430-color-overlay"></div>
                        <div className="ip-pom-block-content"></div>
                    </div>
                    <div className="lp-element ip-pom-block" id="ip-pom-block-546">
                        <div id="ip-pom-block-546-color-overlay"></div>
                        <div className="ip-pom-block-content"></div>
                    </div>
                    <div className="lp-element ip-pom-block" id="ip-pom-block-391">
                        <div id="ip-pom-block-391-color-overlay"></div>
                        <div className="ip-pom-block-content"></div>
                    </div>
                    <div className="lp-element ip-pom-block" id="ip-pom-block-21">
                        <div id="ip-pom-block-21-color-overlay"></div>
                        <div className="ip-pom-block-content"></div>
                    </div>
                </div>
            </div>
        )
    }
}