import React, { Component } from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";

//import './resources/css/top-agent.css';
//import './resources/css/kate.css';
//import './resources/css/main-unbounce.css';
//import './resources/css/main-leaf.css';
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    )
  }

};


ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
