import React from "react";
import { Route, Switch } from "react-router-dom";
//import ViewPage from './components/pageViewer'
//import PageNoAccess from './components/pageNoAccess'
import Kate from "./components/kate";
//import Perkplans from "./components/perkplans";
import TopAgent from "./components/topAgent";

const Routes = () => {
  return (
    <Switch>
      <Route path="/kate-001" exact component={Kate}></Route>
      <Route path="/top-agent-use-eva-fsb" exact component={TopAgent}></Route>
      <Route path="/top-agent-use-eva-generic" exact component={TopAgent}></Route>
      <Route path="/perkplans" exact component={Kate}></Route>
      <Route path="/hooquest" exact component={Kate}></Route>

      <Route component={Kate}></Route>
    </Switch>
  )
};


export default Routes;
