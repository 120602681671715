import React from 'react';

import classes from '../resources/css/backgroundVideo.module.css';

const BackgroundVideo = () => {
    const videoSource = "https://eva-bot.s3.amazonaws.com/videos/kate+001+landingpage.mp4"
    return (
        <div className={classes.Container} >
            <video autoPlay="autoplay" loop="loop" muted className={classes.Video} >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className={classes.Content}>
                <div className={classes.SubContent} >

                </div>
            </div>
        </div>
    )
}

export default BackgroundVideo

// https://medium.com/@Rayyan995/how-to-make-a-background-video-component-in-react-8725e32da272
// https://github.com/Rayyan995/background-video/blob/master/src/components/BackgroundVideo/BackgroundVideo.js